
<template>

        <object :data="Constants.ServerUrl + 'inspectii_finale/inspectie_finala_pdf/' + IdInspectie" type="application/pdf" width="100%" :height="parentHeight">
            <p><a :href="Constants.ServerUrl + 'inspectii_finale/inspectie_finala_pdf/' + IdInspectie"></a></p>
        </object>                  
                
</template>

<script>
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage' 
import Constants from '@/backend/Constants'
export default {
    name: "inspectie_finala_pdf",
    extends: BasePage,

    data(){
        return {
            Constants: Constants,
            IdInspectie: 0,   
            parentHeight: 1000,        
        }
    },
    methods: {
        genereaza() {  
            this.IdInspectie = this.$route.params.inspectie_finala    
        }
    },
    mounted() {
        this.genereaza()
    }
}
</script>

